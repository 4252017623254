/* Tabs and accordions with jfmulticontent and special URL rewriting */

jQuery( document ).ready( function() {

  var _navTabs = $( ".nav-tabs" );

  // The li class active (###EASYACCORDION_ACTIVE### from template file) is used
  // to open the corresponding content
  // Each tab-container
  _navTabs.each( function() {
    var _anchorHref = $( this ).find( "li.active a" ).attr( "href" );
     if ( typeof _anchorHref !== "undefined" ) {
      var _anchorHrefSplitted = _anchorHref.split( "#" );
       if ( _anchorHrefSplitted[ 1 ] !== "" ) {
         $( this ).parent().parent()
           .find( "div.tab-content div#" + _anchorHrefSplitted[ 1 ] )
           .addClass( "active" );
       }
    }
  } );

  // Cares for correct handling of history-back-button
  window.onpopstate = function() {

    // If backbutton is clicked, we simulate a click on the corresponding element
    var _anchor = window.location.hash;

    // Tabs
    if ( jQuery( _anchor ).parents( ".tab-content" ).length ) {
      jQuery( "a[href=\"" + _anchor + "\"]" ).trigger( "click" );
    }

    // Accordions
    if ( jQuery( _anchor ).parents( ".panel" ).length ) {
      jQuery( "a[href=\"" + _anchor + "\"]" ).trigger( "click" );
    }

    if ( jQuery( ".tab-content" ).length && _anchor === "" ) {

      // If there are tabs on the page, but no anchor is given, we add
      // the id of the first tab to the url
      var _splittedHref = jQuery( ".nav-tabs" ).find( "li" ).first().find( "a" )
          .prop( "href" ).split( "#" ),
        _url = window.location.href;
      window.location.href = _url + "#" + _splittedHref[ 1 ];
    }
  };

  /*
   * The tabs...
   */

  // Step 1: Check if we have an tab-related achor in the url and add the id
  // of the first tab if not
  var _href = window.location.hash, _splitUrl = "",
    _splittedHref = "";

  // We check, if the anchor belongs to a tab
  if ( jQuery( _href ).parents( ".tab-content" ).length ) {
    _splitUrl = window.location.hash;
  } else if ( jQuery( ".tab-content" ).length && _href === "" ) {
    _splitUrl = window.location.hash;
  }

  // Step 2: Open all first tab elements and the one,
  // that is given by the url
  _navTabs.each( function() {

    // Open tab that corresponds to url
    $( this ).find( "li" ).each( function() {
      var _anchorHref = $( this ).find( "a" ).attr( "href" );
      if ( _anchorHref === _splitUrl ) {
        $( this ).find( "a" ).trigger( "click" );
      }
    } );
  } );

  // Step3: Add anchor to URL when anchor is clicked
  $( ".nav-tabs li a" ).click( function() {
    _splittedHref = $( this ).prop( "href" ).split( "#" );
    if ( _splittedHref.length > 1 ) {
      _splitUrl = window.location.href.split( "#" );

      // This is needed for preventing too many fireing
      // events (onpopstate)
      if ( _splitUrl[ 1 ] !== _splittedHref[ 1 ] ) {
        window.location.hash = _splittedHref[ 1 ];
      }
    }
  } );

  /*
   * Accordions
   */

  // Adds anchor to URL when anchor is clicked
  jQuery( ".panel-heading h4 a" ).click( function() {
    if ( jQuery( this ).hasClass( "collapsed" ) ) {
      _splitUrl = jQuery( this ).attr( "href" ).split( "#" );
      if ( _splitUrl.length > 1 ) {
        window.location.hash = "#" + _splitUrl[ 1 ];
      }
    }
  } );

  var _hrefWithHash = jQuery( "a[href='" + window.location.hash + "']" );

  // We have something that toggles e.g accordion
  if ( _hrefWithHash.attr( "data-toggle" ) )  {
    _hrefWithHash.trigger( "click" );
  }
} );

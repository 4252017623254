var _body = $( "body" );
var _currentPageId = parseInt( _body.attr( "id" ).split( "-" )[ 1 ] );
var _currentLanguageId = parseInt( _body.data( "language-uid" ) );
var _solrSuggestUrl = "?eID=tx_solr_suggest&id=" + _currentPageId + "&L=" + _currentLanguageId;

// Function to get the url Params (https://www.andrerinas.de/tutorials/jquery-get-parameter-auslesen.html)
$.extend( {
  getUrlVars: function() {
    var vars = [], hash;
    var hashes = window.location.href.slice( window.location.href.indexOf( "?" ) + 1 ).split( "&" );
    for ( var i = 0; i < hashes.length; i++ ) {
      hash = hashes[ i ].split( "=" );
      vars.push( hash[ 0 ] );
      vars[ hash[ 0 ] ] = hash[ 1 ];
    }
    return vars;
  },
  getUrlVar: function( name ) {
    return $.getUrlVars()[ name ];
  }
} );

// We want the search flyout to be visible by default on the search page
$( document ).ready( function() {

  // Put the search term in the search field
  var _searchTerm = $( "#searchValue" ).text();
  var _breadcrumb = $( "#tx-solr-q-breadcrumb" );
  _breadcrumb.val( _searchTerm );

  // Open the search flyout if q param is set
  if ( $.getUrlVar( "q" ) !== undefined ) {
    var _navPanelSearch = $( "#nav-panel-search" );
    _navPanelSearch.addClass( "navigation-main__panel_state-active" );
    $( ".navigation-main__button-search" ).addClass( "navigation-main__button_active" );
    var _headerHeight = $( ".wrap-header" ).outerHeight();
    $( "div.page div.site" ).css( "top", _headerHeight );
    if ( !_navPanelSearch.css( "width" ) ) {
      _navPanelSearch.removeClass( "navigation-main__panel_state-active" );
    }
  }

  var _req = false;

  // Autocomplete
  _breadcrumb.autocomplete( {
    source: function( request, response ) {
      if ( _req ) {
        _req.abort();
        response();
      }

      _req = jQuery.ajax( {
        url: _solrSuggestUrl,
        dataType: "json",
        data: {
          termLowercase: request.term.toLowerCase(),
          termOriginal: request.term
        },
        success: function( data ) {
          _req = false;

          var output = [];

          jQuery.each( data, function( termIndex, term ) {
            if ( term.type === "more" ) {
              output.push( {
                label: term.content,
                value: request.term,
                type: term.type
              } );
            } else {
              output.push( {
                label: term.content,
                value: term.title,
                type: term.type,
                url: term.url
              } );
            }
          } );

          response( output );
        }
      } );
    },
    select: function( event, ui ) {
      if ( ui.item.url !== undefined ) {
        document.location.href = ui.item.url;
      } else {
        this.value = ui.item.value;
        jQuery( event.target ).closest( "form" ).submit();
      }
    },
    delay: 0,
    minLength: 3,
    open: function() {
      _breadcrumb.autocomplete( "widget" ).width( _breadcrumb.outerWidth() );
    }
  } ).data( "ui-autocomplete" )._renderItem = function( ul, item ) {
    return $( "<li class=\"tx_solr_new_autocomplete clearfix\"></li>" )
      .attr( "data-type", item.type )
      .append( item.label )
      .appendTo( ul );
  };

  // Adds tracking for autocomplete search results
  // taken from https://www.simoahava.com/analytics/track-autocomplete-search-google-tag-manager/

  // Set searchField to the search input field.
  // Set timeout to the time you want to wait after the last character in milliseconds.
  // Set minLength to the minimum number of characters that constitutes a valid search.
  var searchField = document.querySelector( "#tx-solr-q-breadcrumb" ),
    timeout = 1000,
    minLength = 3;

  var textEntered = false;

  var timer, searchText;

  var handleInput = function() {
    searchText = searchField ? searchField.value : "";
    if ( searchText.length < minLength ) {
      return;
    }

    dataLayer.push( {
      event: "customSearch",
      customSearchInput: searchText
    } );
    textEntered = false;
  };

  var startTimer = function( e ) {
    textEntered = true;
    window.clearTimeout( timer );
    if ( e.keyCode === 13 ) {
      handleInput();
      return;
    }
    timer = setTimeout( handleInput, timeout );
  };

  if ( searchField !== null ) {
    searchField.addEventListener( "keydown", startTimer, true );
    searchField.addEventListener( "blur", function() {
      if ( textEntered ) {
        window.clearTimeout( timer );
        handleInput();
      }
    }, true );
  }
} );
